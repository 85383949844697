<template>
  <div class="index">
    <!-- 首页 -->
    <div class="banner">
      <img v-if="!$store.state.isPhone" :src="$store.state.bannerUrl + $store.state.bannerImgs.index" alt="">
      <img v-else src="../../assets/images/banner/首页banner.jpg" alt=""/>
    </div>
    <div class="main">
      <div class="indexContent">
        <div class="item" v-for="item in dataList" :key="item.id">
          <img :src="require('../../assets/images/index/' + item.imgPath)" alt="">
          <span class="name">{{ item.name }}</span>
          <span class="title">{{ item.name }}</span>
          <p class="more"><span @click="toPage(item.path)">了解详情</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { touristLogin } from '@/api/gauge'
export default {
  data() {
    return {
      isPc: true,
      dataList: [
        { id: 1, name: '心理健康服务', imgPath: '编组 5@2x.png', path: '/service/index' },
        { id: 2, name: '企业心理服务', imgPath: '编组 6@2x.png', path: '/enterprise/index' },
        { id: 3, name: '心理测评', imgPath: '编组 7@2x.png', path: '/test/gauge' },
        { id: 4, name: '心理科普', imgPath: '编组 2@2x.png', path: '/universal' },
      ]
    }
  },
  methods: {
    // 游客登录
    async tourist() {
      const res = await touristLogin();
      // console.log(res);
      if (res.code === 200) {
        sessionStorage.setItem('userType', 'tourist')
        sessionStorage.setItem('token', res.data.token);
        sessionStorage.setItem('userInfo', JSON.stringify(res.data));
        // this.GaugeList();
      }
    },
    toPage(val) {
      this.$router.push(val)
    }


  },
  mounted() {
    if (!sessionStorage.getItem('token')) {
      this.tourist();

    } else {
      // console.log('已登录');
    }
  },
  created() {
    this.isPc = sessionStorage.getItem('isPc')


  }
}
</script>

<style scoped lang="scss">
.index {

  .banner {
    width: 100%;

    // height: 300px;
    img {
      height: 400px;
      width: 100%;
    }
  }

  .main {

    // width: 1120px;
    // margin:0 auto;
    .indexContent {
      // display: flex;
      width: 100%;
      box-sizing: border-box;
      // flex-wrap: wrap;
      // justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        // display: inline-block;
        // float: left;

        margin-top: 20px;
        // margin-right: 30px;
        // width: 48%;
        width: 530px;
        // height: 210px;
        position: relative;
        text-align: center;
        // &:nth-child(2), &:nth-child(4){
        //   margin-right: 0;
        // }

        &:hover {
          box-shadow: 5px 5px 7px 0px rgba(0, 0, 0, 0.17);

          .title,
          .more {
            display: block;
          }

          .name {
            display: none;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }

        span {
          font-size: 24px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 45px;
          text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
        }

        // span, p{
        //   position: absolute;
        //   // top:0;
        // }
        .name {
          position: absolute;
          bottom: 22px;
          display: inline-block;
          width: 100%;
          // text-align: center;
          left: 0px;
        }

        .title {
          display: none;
          position: absolute;
          // display: block;
          // height: 100%;
          // line-height: 100%;
          bottom: 82px;
          width: 100%;
          // text-align: center;
        }

        .more {
          display: none;
          position: absolute;
          width: 100%;
          bottom: 26px;


          span {
            cursor: pointer;
            text-shadow: none;
            display: block;
            width: 230px;
            height: 45px;
            line-height: 45px;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 600;
            color: #221508;
            margin: auto;
            background: #FACFCF;
            box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.24);
            // text-align: center;
          }
        }
      }
    }
  }
}


// @media (min-width: 1000px) and (max-width: 1200px) {
//   .index {
//     .main {

//       width: 90%;
//       .indexContent {

//        .item{
//         width: 48%;
//        }
//       }
//     }
//   }
// }
// @media (min-width: 850px) and (max-width: 1200px) {
//   .index {
//     .main {

//       width: 90%;
//       .indexContent {

//        .item{
//         width: 48%;
//        }
//       }
//     }
//   }
// }
// @media (min-width: 750px) and (max-width: 999px) {
//   .index {
//     .main {

//       width: 650px;
//       .indexContent {

//        .item{
//         width: 310px;
//        }
//       }
//     }
//   }
// }
@media screen and (min-width: 1200px) {
  .index .main .indexContent .item {

    &:nth-child(2),
    &:nth-child(4) {
      margin-left: 30px;
    }
  }

}

//   .index {
//     .main {
//       width: 90%;
//       // width: 400px;
//       .indexContent {

//        .item{
//         width: 100%;
//        }
//       }
//     }
//   }
// }</style>